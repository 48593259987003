import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faGhost,
    faChevronDown,
    faCog,
    faRocket,
    faTh,
} from '@fortawesome/free-solid-svg-icons';
import { faMountains } from '@fortawesome/pro-solid-svg-icons';
import {
    faCog as faCogOutline,
    faUser as faUserOutline,
} from '@fortawesome/pro-light-svg-icons';
import serviceTypes from 'js/enums/service-types.enum';
import { getJwtPayload } from 'js/utils';
import Box from 'js/components/box/box';
import DropdownButton from 'js/components/dropdown-menu/button';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownTitle from 'js/components/dropdown-menu/title';
import SwitchAccountDrawer from 'js/components/navigation/switch-account-drawer';
import Text from 'js/components/text/text';
import Logo from 'media/images/logos/logo.svg';
import LogoStaging from 'media/images/logos/logo-staging.svg';
import LogoDemo from 'media/images/logos/logo-demo.svg';
import { accountMgmtEmailAddress } from 'js/constants';
import LogoSilverpush from './images/silverpush-logo.svg';
import BottomBg from './images/bottom-bg.svg';
import MountainsOutline from './images/discover.svg';
import RocketOutline from './images/insights.svg';
import ThOutline from './images/contexts.svg';
import styles from './navigation.module.scss';
import Row from '../grid/row';
import Col from '../grid/column';

const cx = classNames.bind(styles);

function InactiveAccountBanner() {
    return (
        <div className={styles['inactive-account-banner']}>
            <Box padding={['base']}>
                <Row justifyContent="center">
                    <Col span="auto">
                        <Text size="large">
                            This account is inactive. Reach out to{' '}
                            <a
                                href={`mailto:${accountMgmtEmailAddress}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {accountMgmtEmailAddress}
                            </a>{' '}
                            to have it re-instated.
                        </Text>
                    </Col>
                </Row>
            </Box>
        </div>
    );
}

function Navigation() {
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [isAccountDrawerVisible, setAccountDrawerVisible] = useState(false);
    const [showAccountNameLabel, setShowAccountNameLabel] = useState(false);

    const today = new Date();

    // detect if using a local UI checkout with a remote API:
    const isUnsafe =
        process.env.REACT_APP_CLUSTER !== 'dev' &&
        window.location.protocol !== 'https:';

    const {
        email,
        is_superuser: isSuperuser,
        account_logo: accountLogo,
        account_group: accountGroup,
        account_name: accountName,
        account_is_active: accountIsActive,
        services,
    } = getJwtPayload(localStorage.getItem('AuthToken'));
    const canToggleAccount = isSuperuser || accountGroup;
    const insightsService = services.find(
        (service) => service.name === serviceTypes.INSIGHTS,
    );
    const discoverService = services.find(
        (service) => service.name === serviceTypes.DISCOVER,
    );

    const userMenuClasses = cx({
        link: true,
        active: showUserMenu,
    });

    const toggleShowUserMenu = () => {
        setShowUserMenu((previous) => !previous);
    };

    const toggleAccountDrawer = () => {
        setAccountDrawerVisible((previous) => !previous);
    };

    const getLogo = () => {
        if (accountName.toLowerCase() === 'silverpush') {
            return LogoSilverpush;
        }
        if (process.env.REACT_APP_CLUSTER === 'staging') {
            return LogoStaging;
        }
        if (process.env.REACT_APP_CLUSTER === 'demo') {
            return LogoDemo;
        }
        return Logo;
    };

    return (
        <>
            {accountIsActive === false && <InactiveAccountBanner />}
            <div className={cx({ container: true, unsafe: isUnsafe })}>
                {!isUnsafe && (
                    <div className={styles.bg}>
                        <img src={BottomBg} alt="" />
                    </div>
                )}

                <div className={styles['top-container']}>
                    <div className={styles['logo-container']}>
                        <Link to="/">
                            <img
                                className={styles.logo}
                                src={getLogo()}
                                alt="4D"
                            />
                        </Link>
                    </div>

                    <NavLink
                        activeClassName={styles.active}
                        className={styles.link}
                        to="/context/"
                        isActive={(match, { pathname }) =>
                            /context|advertisers|campaigns/i.test(pathname)
                        }
                    >
                        <p className={styles['icon-wrapper']}>
                            <FontAwesomeIcon
                                icon={faTh}
                                className={cx('icon', 'hover-show')}
                            />
                            <img
                                src={ThOutline}
                                className={cx('icon', 'hover-hidden')}
                                alt=""
                            />
                        </p>
                        <p className={styles.name}>Contexts</p>
                    </NavLink>

                    {insightsService && (
                        <NavLink
                            activeClassName={styles.active}
                            className={styles.link}
                            to="/insights/"
                            isActive={(match, { pathname }) =>
                                /insights/i.test(pathname)
                            }
                        >
                            <p className={styles['icon-wrapper']}>
                                <FontAwesomeIcon
                                    icon={faRocket}
                                    className={cx('icon', 'hover-show')}
                                />
                                <img
                                    src={RocketOutline}
                                    className={cx('icon', 'hover-hidden')}
                                    alt=""
                                />
                            </p>
                            <p className={styles.name}>Insights</p>
                        </NavLink>
                    )}

                    {discoverService && (
                        <NavLink
                            activeClassName={styles.active}
                            className={styles.link}
                            to="/discover/feature/"
                            isActive={(match, { pathname }) =>
                                /discover/i.test(pathname)
                            }
                        >
                            <p className={styles['icon-wrapper']}>
                                <FontAwesomeIcon
                                    icon={faMountains}
                                    className={cx('icon', 'hover-show')}
                                />
                                <img
                                    src={MountainsOutline}
                                    className={cx('icon', 'hover-hidden')}
                                    alt=""
                                />
                            </p>
                            <p className={styles.name}>Discover</p>
                        </NavLink>
                    )}

                    <NavLink
                        activeClassName={styles.active}
                        className={styles.link}
                        to="/settings/"
                    >
                        <p className={styles['icon-wrapper']}>
                            <FontAwesomeIcon
                                icon={faCog}
                                className={cx('icon', 'hover-show')}
                            />
                            <FontAwesomeIcon
                                icon={faCogOutline}
                                className={cx('icon', 'hover-hidden')}
                            />
                        </p>
                        <p className={styles.name}>Settings</p>
                    </NavLink>

                    <span
                        className={userMenuClasses}
                        onClick={toggleShowUserMenu}
                        aria-hidden="true"
                    >
                        <p className={styles['icon-wrapper']}>
                            <FontAwesomeIcon
                                icon={
                                    today.toDateString().includes('Oct 31')
                                        ? faGhost
                                        : faUser
                                }
                                className={cx('icon', 'hover-show')}
                            />
                            <FontAwesomeIcon
                                icon={faUserOutline}
                                className={cx('icon', 'hover-hidden')}
                            />
                        </p>
                        <p className={styles.name}>
                            You <FontAwesomeIcon icon={faChevronDown} />
                            <DropdownMenu
                                content={
                                    <ul>
                                        {email && (
                                            <DropdownTitle>
                                                {email}
                                            </DropdownTitle>
                                        )}

                                        <DropdownButton to="/settings/your-profile/details/">
                                            Edit Your Details
                                        </DropdownButton>

                                        <DropdownButton to="/settings/your-profile/password/">
                                            Change Your Password
                                        </DropdownButton>

                                        {canToggleAccount ? (
                                            <DropdownButton
                                                onClick={toggleAccountDrawer}
                                            >
                                                Switch Account
                                            </DropdownButton>
                                        ) : null}

                                        <DropdownButton to="/logout/">
                                            Logout
                                        </DropdownButton>
                                    </ul>
                                }
                                onHide={() => setShowUserMenu(false)}
                                showMenu={showUserMenu}
                                placement="right-end"
                            />
                        </p>
                    </span>
                </div>

                {accountLogo && (
                    <div
                        className={styles['account-logo-container']}
                        onMouseEnter={() => setShowAccountNameLabel(true)}
                        onMouseLeave={() => setShowAccountNameLabel(false)}
                    >
                        <img
                            className={styles['account-logo']}
                            src={accountLogo}
                            alt="Account Logo"
                        />

                        <DropdownMenu
                            content={
                                <Box padding={['small']}>
                                    <Text size="small">Account</Text>
                                    <Text weight="bolder" size="base">
                                        {accountName}
                                    </Text>
                                </Box>
                            }
                            showMenu={showAccountNameLabel}
                            placement="right-end"
                        />
                    </div>
                )}
            </div>

            {isAccountDrawerVisible && (
                <SwitchAccountDrawer toggleDrawer={toggleAccountDrawer} />
            )}
        </>
    );
}

Navigation.defaultProps = {};

Navigation.propTypes = {};

export default Navigation;
